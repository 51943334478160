<script setup lang="ts"></script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      v-for="(path, index) in paths"
      :key="index"
      :d="path.d"
      :stroke="path.stroke"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      :class="`animate-path${index + 1}`"
    />
  </svg>
</template>

<script>
export default {
  data() {
    return {
      paths: [
        { d: 'M12 6L12 3', stroke: '#070103' },
        { d: 'M17.6572 6.34277L18.3643 5.63567', stroke: '#b1b1b3' },
        { d: 'M19 12L21 12', stroke: '#a1a2a4' },
        { d: 'M16.9502 16.9497L18.3644 18.3639', stroke: '#8a8b8d' },
        { d: 'M12 21L12 19', stroke: '#7e7e80' },
        { d: 'M5.63672 18.3638L7.05093 16.9496', stroke: '#626364' },
        { d: 'M3 12L6 12', stroke: '#4c4c4e' },
        { d: 'M5.63574 5.63574L7.75706 7.75706', stroke: '#3a3a3b' },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@use 'sass:list';

$colors: (
  #b1b1b3,
  #a1a2a4,
  #8a8b8d,
  #7e7e80,
  #626364,
  #4c4c4e,
  #3a3a3b,
  #070103
);

@for $i from 1 through 8 {
  @keyframes color-change#{$i} {
    0% {
      stroke: #{list.nth($colors, ($i + 1) % 8 + 1)};
    }
    12.5% {
      stroke: #{list.nth($colors, ($i + 8) % 8 + 1)};
    }
    25% {
      stroke: #{list.nth($colors, ($i + 7) % 8 + 1)};
    }
    37.5% {
      stroke: #{list.nth($colors, ($i + 6) % 8 + 1)};
    }
    50% {
      stroke: #{list.nth($colors, ($i + 5) % 8 + 1)};
    }
    62.5% {
      stroke: #{list.nth($colors, ($i + 4) % 8 + 1)};
    }
    75% {
      stroke: #{list.nth($colors, ($i + 3) % 8 + 1)};
    }
    87.5% {
      stroke: #{list.nth($colors, ($i + 2) % 8 + 1)};
    }
    100% {
      stroke: #{list.nth($colors, ($i + 1) % 8 + 1)};
    }
  }

  .animate-path#{$i} {
    animation: color-change#{$i} 1s infinite linear;
  }
}
</style>
